<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <path
      style="fill:#FFDC64;"
      d="M441.379,229.517H335.448V476.69h105.931c4.875,0,8.828-3.953,8.828-8.828V238.345
	C450.207,233.47,446.254,229.517,441.379,229.517z"
    />
    <rect
      x="379.586"
      y="229.517"
      style="fill:#5B5D6E;"
      width="35.31"
      height="247.172"
    />
    <rect
      x="335.448"
      y="229.517"
      style="fill:#FFC850;"
      width="26.483"
      height="247.172"
    />
    <path
      style="fill:#5B5D6E;"
      d="M308.966,512H97.103c-19.501,0-35.31-15.809-35.31-35.31V105.931c0-19.501,15.809-35.31,35.31-35.31
	h211.862c19.501,0,35.31,15.809,35.31,35.31V476.69C344.276,496.191,328.467,512,308.966,512z"
    />
    <g>
      <path
        style="fill:#464655;"
        d="M105.931,476.69V105.931c0-19.501,15.809-35.31,35.31-35.31H97.103
		c-19.501,0-35.31,15.809-35.31,35.31V476.69c0,19.501,15.809,35.31,35.31,35.31h44.138C121.74,512,105.931,496.191,105.931,476.69z
		"
      />
      <path
        style="fill:#464655;"
        d="M308.966,141.241H97.103c-4.879,0-8.828-3.953-8.828-8.828c0-4.875,3.948-8.828,8.828-8.828h211.862
		c4.879,0,8.828,3.953,8.828,8.828C317.793,137.289,313.845,141.241,308.966,141.241z"
      />
    </g>
    <g>
      <path
        style="fill:#C7CFE2;"
        d="M207.448,335.448h-8.828c-9.751,0-17.655-7.904-17.655-17.655l0,0
		c0-9.751,7.904-17.655,17.655-17.655h8.828c9.751,0,17.655,7.904,17.655,17.655l0,0
		C225.103,327.544,217.199,335.448,207.448,335.448z"
      />
      <path
        style="fill:#C7CFE2;"
        d="M207.448,406.069h-8.828c-9.751,0-17.655-7.904-17.655-17.655l0,0
		c0-9.751,7.904-17.655,17.655-17.655h8.828c9.751,0,17.655,7.904,17.655,17.655l0,0
		C225.103,398.165,217.199,406.069,207.448,406.069z"
      />
    </g>
    <path
      style="fill:#FFDC64;"
      d="M207.448,476.69h-8.828c-9.751,0-17.655-7.904-17.655-17.655l0,0c0-9.751,7.904-17.655,17.655-17.655
	h8.828c9.751,0,17.655,7.904,17.655,17.655l0,0C225.103,468.786,217.199,476.69,207.448,476.69z"
    />
    <g>
      <path
        style="fill:#C7CFE2;"
        d="M286.897,335.448h-8.828c-9.751,0-17.655-7.904-17.655-17.655l0,0
		c0-9.751,7.904-17.655,17.655-17.655h8.828c9.751,0,17.655,7.904,17.655,17.655l0,0
		C304.552,327.544,296.648,335.448,286.897,335.448z"
      />
      <path
        style="fill:#C7CFE2;"
        d="M286.897,406.069h-8.828c-9.751,0-17.655-7.904-17.655-17.655l0,0
		c0-9.751,7.904-17.655,17.655-17.655h8.828c9.751,0,17.655,7.904,17.655,17.655l0,0
		C304.552,398.165,296.648,406.069,286.897,406.069z"
      />
    </g>
    <path
      style="fill:#C8FF82;"
      d="M286.897,476.69h-8.828c-9.751,0-17.655-7.904-17.655-17.655l0,0c0-9.751,7.904-17.655,17.655-17.655
	h8.828c9.751,0,17.655,7.904,17.655,17.655l0,0C304.552,468.786,296.648,476.69,286.897,476.69z"
    />
    <g>
      <path
        style="fill:#C7CFE2;"
        d="M128,335.448h-8.828c-9.751,0-17.655-7.904-17.655-17.655l0,0c0-9.751,7.904-17.655,17.655-17.655
		H128c9.751,0,17.655,7.904,17.655,17.655l0,0C145.655,327.544,137.751,335.448,128,335.448z"
      />
      <path
        style="fill:#C7CFE2;"
        d="M128,406.069h-8.828c-9.751,0-17.655-7.904-17.655-17.655l0,0c0-9.751,7.904-17.655,17.655-17.655
		H128c9.751,0,17.655,7.904,17.655,17.655l0,0C145.655,398.165,137.751,406.069,128,406.069z"
      />
    </g>
    <path
      style="fill:#FF507D;"
      d="M128,476.69h-8.828c-9.751,0-17.655-7.904-17.655-17.655l0,0c0-9.751,7.904-17.655,17.655-17.655H128
	c9.751,0,17.655,7.904,17.655,17.655l0,0C145.655,468.786,137.751,476.69,128,476.69z"
    />
    <path
      style="fill:#B4E66E;"
      d="M300.138,167.724H105.931c-4.875,0-8.828,3.953-8.828,8.828V256c0,4.875,3.953,8.828,8.828,8.828
	h194.207c4.875,0,8.828-3.953,8.828-8.828v-79.448C308.966,171.677,305.013,167.724,300.138,167.724z"
    />
    <polygon
      style="fill:#E4EAF8;"
      points="275.862,8.828 251.586,0 227.31,8.828 203.034,0 178.759,8.828 154.483,0 130.207,8.828 
	105.931,0 105.931,141.241 300.138,141.241 300.138,0 "
    />
    <path
      style="fill:#707487;"
      d="M264.828,105.931h-35.31c-4.879,0-8.828-3.953-8.828-8.828c0-4.875,3.948-8.828,8.828-8.828h35.31
	c4.879,0,8.828,3.953,8.828,8.828C273.655,101.978,269.707,105.931,264.828,105.931z"
    />
    <g>
      <path
        style="fill:#7F8499;"
        d="M194.207,105.931h-52.966c-4.879,0-8.828-3.953-8.828-8.828c0-4.875,3.948-8.828,8.828-8.828h52.966
		c4.879,0,8.828,3.953,8.828,8.828C203.034,101.978,199.086,105.931,194.207,105.931z"
      />
      <path
        style="fill:#7F8499;"
        d="M264.828,70.621h-17.655c-4.879,0-8.828-3.953-8.828-8.828s3.948-8.828,8.828-8.828h17.655
		c4.879,0,8.828,3.953,8.828,8.828S269.707,70.621,264.828,70.621z"
      />
      <path
        style="fill:#7F8499;"
        d="M211.862,70.621h-17.655c-4.879,0-8.828-3.953-8.828-8.828s3.948-8.828,8.828-8.828h17.655
		c4.879,0,8.828,3.953,8.828,8.828S216.742,70.621,211.862,70.621z"
      />
    </g>
    <path
      style="fill:#707487;"
      d="M158.897,70.621h-17.655c-4.879,0-8.828-3.953-8.828-8.828s3.948-8.828,8.828-8.828h17.655
	c4.879,0,8.828,3.953,8.828,8.828S163.776,70.621,158.897,70.621z"
    />
    <rect
      x="105.931"
      y="123.586"
      style="fill:#D5DCED;"
      width="194.207"
      height="17.655"
    />
    <g>
      <path
        style="fill:#C8FF82;"
        d="M199.769,243.242l34.878-52.318c1.58-2.37-0.119-5.545-2.967-5.545h-50.404
		c-2.952,0-5.707,1.475-7.345,3.93l-34.915,52.373c-1.565,2.347,0.118,5.49,2.938,5.49h50.471
		C195.377,247.172,198.133,245.697,199.769,243.242z"
      />
      <path
        style="fill:#C8FF82;"
        d="M243.907,243.242l34.879-52.318c1.58-2.37-0.119-5.545-2.967-5.545h-15.095
		c-2.952,0-5.707,1.475-7.345,3.93l-34.915,52.373c-1.565,2.347,0.118,5.49,2.938,5.49h15.16
		C239.514,247.172,242.271,245.697,243.907,243.242z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
